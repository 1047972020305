<template>
  <div class="hidden md:block">
    <el-button
      size="large"
      type="primary"
      plain
      @click="drawer = true"
      class="md:fixed md:-right-1 md:top-20 md:z-50"
      style="height: 65px"
      ><div class="-ml-2 flex items-center">
        <ChevronLeftIcon class="h-9 w-9" aria-hidden="true" /><span
          class="text-lg"
          >Open Sidebar</span
        >
      </div></el-button
    >
  </div>
  <div class="sidebar-button-mobile md:hidden">
    <el-button
      @click="drawer = true"
      class="z-1 fixed inset-x-0 bottom-0"
      type="primary"
      size="large"
      plain
      ><span class="text-lg">Open Sidebar</span></el-button
    >
  </div>

  <div class="event-slide-over">
    <el-drawer
      v-model="drawer"
      :lock-scroll="false"
      :modal="false"
      :z-index="99"
      :size="drawerSize"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <template #header>
        <div class="flex items-center justify-between">
          <h4>Edit Registry</h4>
          <!-- <router-link :to="{ name: 'dashboard' }">
            <el-button size="large" round>
              <ChevronLeftIcon class="mr-1 h-5 w-5" aria-hidden="true" />
              Dashboard</el-button
            ></router-link
          > -->
        </div>
      </template>
      <template #default>
        <div>
          <div class="divide-y divide-gray-200">
            <div>
              <div>
                <div>
                  <el-tabs v-model="mainTabs.activeTab">
                    <el-tab-pane
                      v-for="(tab, index) in mainTabs.tabs"
                      :key="tab.name"
                      :label="tab.name"
                      :name="index"
                    >
                      <div>
                        <h3 class="font-medium text-gray-900">
                          {{ tab.heading }}
                        </h3>
                        <p class="mt-1 text-sm text-gray-600">
                          {{ tab.description }}
                        </p>
                      </div>
                      <el-form @submit.prevent label-position="top">
                        <div v-show="mainTabs.activeTab == 0 && event" class="">
                          <el-collapse
                            class="mt-4"
                            @change="scrollAnchor"
                            accordion
                          >
                            <el-collapse-item title="Basic Details" name="1">
                              <div class="space-y-4">
                                <div>
                                  <div class="grid grid-cols-2 gap-x-2">
                                    <p
                                      class="el-form-item__label col-span-2"
                                      style="justify-content: flex-start"
                                    >
                                      Your Names
                                    </p>
                                    <el-form-item prop="name_host1">
                                      <el-input
                                        v-model="event.name_host1"
                                        size="large"
                                      />
                                    </el-form-item>
                                    <el-form-item prop="name_host1">
                                      <el-input
                                        v-model="event.name_host2"
                                        size="large"
                                      />
                                    </el-form-item>
                                  </div>
                                </div>
                                <el-form-item
                                  label="Description"
                                  prop="description"
                                >
                                  <el-input
                                    :rows="5"
                                    type="textarea"
                                    v-model="event.description"
                                  />
                                </el-form-item>
                                <el-form-item label="Event Date" prop="date">
                                  <el-date-picker
                                    v-model="event.start_datetime"
                                    type="date"
                                    size="large"
                                    :clearable="false"
                                    placeholder="Pick a Date"
                                    :format="dateFormatCode"
                                    :value-format="dateValueFormatCode"
                                    :disabled-date="
                                      function (date) {
                                        return date < Date.now();
                                      }
                                    "
                                    :disabled="event.has_been_live"
                                  />
                                  <div>
                                    <el-checkbox
                                      v-model="event.properties.show_date"
                                      :disabled="!event.start_datetime"
                                      label="Display on my registry"
                                      size="large"
                                    />
                                  </div>
                                </el-form-item>
                                <el-form-item
                                  label="Instagram Hashtag"
                                  prop="instagram"
                                >
                                  <el-input
                                    v-model="event.hashtag"
                                    size="large"
                                    placeholder="WhenHarryWedSally"
                                  >
                                    <template #prefix>
                                      <HashtagIcon
                                        class="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </template>
                                  </el-input>
                                </el-form-item>
                              </div>
                            </el-collapse-item>
                            <el-collapse-item
                              title="Additional Details"
                              name="2"
                            >
                              <CustomFieldsSection
                                v-if="event"
                                :event="event"
                              />
                            </el-collapse-item>
                          </el-collapse>
                        </div>
                        <div v-show="mainTabs.activeTab == 1" class="pb-5 pt-2">
                          <div>
                            <el-radio-group
                              v-model="event.properties.img_source"
                              class="mb-4"
                            >
                              <el-radio-button
                                class="capitalize"
                                v-for="option in imageOptions"
                                :key="option.key"
                                :label="option.key"
                                >{{ option.name }}</el-radio-button
                              >
                            </el-radio-group>
                            <p
                              v-if="
                                event.properties.img_source == 'img' &&
                                !(
                                  event.properties.img_source == 'img' &&
                                  this.event.img == null
                                )
                              "
                              class="mb-4"
                            >
                              Click
                              <span
                                @click="showImageUploader = true"
                                class="cursor-pointer font-bold text-primary underline"
                                >here</span
                              >
                              to replace your current image.
                            </p>
                            <ImageUpload
                              v-if="
                                (event.properties.img_source == 'img' &&
                                  this.event.img == null) ||
                                (showImageUploader &&
                                  event.properties.img_source == 'img')
                              "
                              @imageUploaded="
                                (file) => {
                                  handleImage(file[0], 'event');
                                }
                              "
                            />

                            <!-- Show preview of image in slideover only on mobile -->
                            <div
                              v-if="event.imgPreview"
                              class="mt-4 aspect-video w-full overflow-hidden rounded-lg border border-gray-200 lg:hidden"
                            >
                              <div
                                v-lazy:background-image="event.imgPreview"
                                class="pointer-events-none flex h-full w-full items-center justify-center bg-cover bg-center object-scale-down"
                              ></div>
                            </div>
                          </div>
                          <div
                            v-show="
                              event.properties.img_source == 'img_url_stock'
                            "
                          >
                            <ImageGallerySearch
                              @imageSelected="
                                (url) => {
                                  handleStockImage(url, 'event');
                                }
                              "
                            />
                          </div>
                          <div
                            v-show="event.properties.img_source == 'img_url'"
                          >
                            <p class="mb-2 text-sm font-medium text-gray-900">
                              Paste the link to an image below
                            </p>
                            <el-input
                              v-model="event.img_url"
                              type="text"
                              size="large"
                            />
                          </div>
                        </div>
                        <div
                          v-show="mainTabs.activeTab == 2"
                          class="space-y-6 pb-5 pt-2"
                        >
                          <el-collapse class="mt-4">
                            <el-collapse-item title="Layout" name="1">
                              <LayoutPicker :event="event" />
                            </el-collapse-item>
                            <el-collapse-item title="Heading Font" name="2">
                              <el-select
                                v-model="event.properties.font"
                                placeholder="Select"
                                size="large"
                                :style="{ fontFamily: event.properties.font }"
                              >
                                <el-option
                                  v-for="font in fonts"
                                  :key="font"
                                  :label="font"
                                  :value="font"
                                >
                                  <li>
                                    <span
                                      class="text-lg"
                                      :style="{
                                        fontFamily: font,
                                      }"
                                      >{{ font }}</span
                                    >
                                  </li></el-option
                                >
                              </el-select>
                              <div class="mt-3">
                                <el-checkbox
                                  v-model="event.properties.fontAll"
                                  label="Apply to all section headings"
                                  size="large"
                                  style="height: 0px"
                                />
                              </div>
                            </el-collapse-item>
                            <el-collapse-item title="Colors" name="3">
                              <ColorPalettes
                                @clicked-palette="
                                  (palette) => setEventColors(palette)
                                "
                                :event="event"
                              />
                              <span
                                @click="showColorPickers = !showColorPickers"
                                class="mt-4 block cursor-pointer text-sm font-medium text-gray-900 underline"
                                >Customize colors</span
                              >
                              <div class="mt-4" v-if="showColorPickers">
                                <div
                                  class="grid grid-cols-2 gap-y-2 md:grid-cols-4"
                                >
                                  <div
                                    v-for="(colorPicker, index) in colorPickers"
                                    :key="index"
                                    class="w-10/12 md:w-full"
                                    v-show="
                                      !(
                                        currentLayout == 'full' &&
                                        colorPicker.uiElement == 'background'
                                      ) &&
                                      !(
                                        currentLayout == 'center' &&
                                        colorPicker.uiElement == 'accent'
                                      )
                                    "
                                  >
                                    <p
                                      class="text-center text-xs font-bold uppercase"
                                    >
                                      {{ toTitleCase(colorPicker.uiElement) }}
                                    </p>
                                    <ColorPicker
                                      :color="
                                        event.properties.colors[
                                          colorPicker.uiElement
                                        ]
                                      "
                                      :visible-formats="['hex']"
                                      alpha-channel="hide"
                                      copy-button="hide"
                                      @color-change="
                                        (color) =>
                                          (event.properties.colors[
                                            colorPicker.uiElement
                                          ] = color.cssColor)
                                      "
                                    >
                                    </ColorPicker>
                                  </div>
                                </div>
                              </div>
                            </el-collapse-item>
                            <el-collapse-item title="More design" name="4">
                              <div
                                v-if="
                                  currentLayout == 'split 2' ||
                                  currentLayout == 'center'
                                "
                              >
                                <p
                                  class="el-form-item__label"
                                  style="justify-content: flex-start"
                                >
                                  Image Shape
                                </p>
                                <el-form-item>
                                  <el-select
                                    v-model="event.properties.clipPath"
                                    placeholder="Select"
                                    size="large"
                                    clearable
                                  >
                                    <el-option
                                      v-for="shape in clipPaths"
                                      :key="shape.name"
                                      :label="toTitleCase(shape.name)"
                                      :value="shape.path"
                                    />
                                  </el-select>
                                </el-form-item>
                              </div>
                              <div class="mt-2">
                                <p
                                  class="el-form-item__label"
                                  style="justify-content: flex-start"
                                >
                                  Section Divider
                                </p>
                                <el-form-item>
                                  <el-select
                                    v-model="event.properties.divider"
                                    placeholder="Select"
                                    size="large"
                                  >
                                    <el-option
                                      v-for="divider in dividers"
                                      :key="divider.name"
                                      :label="toTitleCase(divider.name)"
                                      :value="divider.name"
                                    />
                                  </el-select>
                                </el-form-item>
                                <!-- <div class="mt-4">
                                  <el-checkbox
                                    v-model="event.properties.animate"
                                    label="Animate registry"
                                    size="large"
                                    style="height: 0px"
                                  />
                                </div> -->
                              </div>
                            </el-collapse-item>
                          </el-collapse>
                        </div>
                      </el-form>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="space-x-4">
          <el-button
            @click="this.drawer = false"
            type="primary"
            size="large"
            plain
          >
            <span class="text-lg">Hide</span>
          </el-button>
          <el-button @click="$emit('submit')" type="primary" size="large">
            <span class="text-lg">Save</span>
          </el-button>
        </div>
      </template>
    </el-drawer>
  </div>
</template>

<script>
import {
  XMarkIcon,
  ArrowUturnLeftIcon,
  HashtagIcon,
} from "@heroicons/vue/24/outline";
import {
  LinkIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
} from "@heroicons/vue/20/solid";
import LayoutPicker from "@/components/admin/LayoutPicker.vue";
import MoodPicker from "@/components/admin/MoodPicker.vue";
import { ColorPicker } from "vue-accessible-color-picker";
import ImageUpload from "@/components/admin/ImageUpload.vue";
import ImageGallerySearch from "@/components/admin/ImageGallerySearch.vue";
import CustomFieldsSection from "@/components/admin/CustomFieldsSection.vue";
import ColorPalettes from "./ColorPalettes.vue";

import colors from "@/mixins/colors.js";
import eventStyles from "@/mixins/eventStyles.js";
import ImageUploadMixin from "@/mixins/ImageUpload.js";

export default {
  components: {
    XMarkIcon,
    LinkIcon,
    PlusIcon,
    ArrowUturnLeftIcon,
    QuestionMarkCircleIcon,
    ChevronLeftIcon,
    LayoutPicker,
    MoodPicker,
    ColorPalettes,
    ColorPicker,
    ImageUpload,
    ImageGallerySearch,
    HashtagIcon,
    CustomFieldsSection,
  },
  props: ["event", "open"],
  mixins: [colors, eventStyles, ImageUploadMixin],
  emits: ["submit", "toggle-drawer"],

  data() {
    return {
      keyPress: false,
      drawer: true,
      showColorPickers: false,
      mainTabs: {
        activeTab: 0,
        tabs: [
          {
            name: "Details",
            heading: "Event Details",
            description:
              "Edit your basic event details. These will show in the main section.",
          },
          {
            name: "Image",
            heading: "Event Image",
            description:
              "Select an option below to display an image for your event.",
          },
          {
            name: "Layout & Customization",
            heading: "Layout & Customization",
            description: "Customize the look and feel of your registry.",
          },
        ],
      },
    };
  },

  computed: {
    drawerSize() {
      return this.userMobile ? "100%" : "400px";
    },
  },

  methods: {
    setEventColors(palette) {
      const eventColorsObject = this.event.properties.colors;
      const { background, paragraph } = palette;
      const colors = {
        background: background,
        paragraph: paragraph,
        accent: paragraph,
        heading: paragraph,
        additionalDetails: paragraph,
      };

      Object.assign(eventColorsObject, colors);
    },
    scrollAnchor(e) {
      let el;
      if (e == 1) {
        el = document.querySelector("#main-section");
      } else if (e == 2) {
        el = document.querySelector("#custom-fields");
      }

      if (el) {
        el.scrollIntoView({
          behavior: "smooth", // smooth scroll
          block: "start", // the upper border of the element will be aligned at the top of the visible part of the window of the scrollable area.
        });
      }
    },
  },

  watch: {
    drawer() {
      this.$emit("toggle-drawer", this.drawer);
    },
  },
};
</script>

<style>
.event-slide-over {
  .dp__input_readonly {
    font-size: 0.9rem;
  }

  .vacp-copy-button,
  .vacp-range-input-label-text--hue {
    display: none;
  }

  .el-drawer {
    top: 48px !important;
    height: calc(100% - 48px) !important;
  }

  .el-drawer__header {
    margin-bottom: 0 !important;
    @apply border-t border-gray-300 bg-primary p-5 text-white;

    button {
      @apply text-base;
    }
  }

  .el-overlay {
    background-color: transparent !important;
  }

  .el-icon:hover {
    @apply text-white opacity-50 transition-opacity;
  }

  .el-drawer__close {
    display: none !important;
  }

  .el-form-item {
    @apply mb-0 !important;
  }

  .el-textarea__inner {
    @apply text-black !important;
  }

  .el-date-editor {
    @apply mt-0 w-full !important;
  }
  .el-collapse-item__header {
    @apply text-sm !important;
  }
}
.sidebar-button-mobile {
  .el-button {
    @apply rounded-none !important;
  }
}
</style>
