<template>
  <!-- loader div -->
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-background="#ffffff"
  ></div>
  <div
    v-if="event"
    :class="drawerState ? 'w-[calc(100%-400px)]' : 'w-full'"
    class="transition-all duration-300 ease-in-out"
  >
    <!-- <NavBar
      class="lg:z-[5000] lg:-translate-y-4 lg:opacity-0 lg:transition-all lg:duration-500 lg:hover:translate-y-0 lg:hover:opacity-100"
    /> -->
    <div
      class="pb-6 pt-12 lg:pb-0"
      id="main-section"
      :class="
        event && event.properties.layout != 'split' ? 'overflow-x-hidden' : null
      "
    >
      <div class="hero">
        <SplitLayout
          v-if="event.properties && event.properties.layout == 'split'"
          :event="event"
          :drawerState="drawerState"
        />
        <SplitLayout2
          v-if="event.properties && event.properties.layout == 'split 2'"
          :event="event"
        />
        <CenterLayout
          v-if="event.properties && event.properties.layout == 'center'"
          :event="event"
        />
        <FullLayout
          v-if="event.properties && event.properties.layout == 'full'"
          :event="event"
        />
      </div>
      <div
        v-if="
          event.properties &&
          event.properties.showCustomFields &&
          event.properties.layout != 'split'
        "
        class="mx-auto border-b border-gray-200 bg-white p-8 lg:p-0 lg:px-16 lg:py-20"
        id="custom-fields"
      >
        <CustomFields :event="event" />
      </div>
      <EventDetailsSlideOver
        v-if="event"
        @submit="editEvent()"
        :event="event"
        @toggle-drawer="(param) => (drawerState = param)"
      />
      <el-dialog v-model="dialogVisible" title="Unsaved Changes" width="30%">
        <span>You have unsaved changes. Do you want to save these?</span>
        <template #footer>
          <span class="space-x-2">
            <el-button @click="navigate()" type="primary" size="large" plain
              >Don't Save</el-button
            >
            <el-button @click="editEvent()" type="primary" size="large"
              >Save</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/stores/UserStore";
import { mapActions } from "pinia";

import NavBar from "@/components/admin/NavBar.vue";
import SplitLayout from "@/layouts/event/Split.vue";
import SplitLayout2 from "@/layouts/event/Split_2.vue";
import CenterLayout from "@/layouts/event/Center.vue";
import FullLayout from "@/layouts/event/Full.vue";
import CustomFields from "@/layouts/event/sections/CustomFields.vue";
import EventDetailsSlideOver from "@/components/admin/EventDetailsSlideOver.vue";
import eventStyles from "@/mixins/eventStyles.js";

export default {
  components: {
    NavBar,
    SplitLayout,
    SplitLayout2,
    CenterLayout,
    FullLayout,
    CustomFields,
    EventDetailsSlideOver,
  },

  mixins: [eventStyles],

  data() {
    return {
      loading: true,
      event: "",
      dialogVisible: false,
      isLoaded: false,
      eventModified: false,
      toURL: null,
      drawerState: true,
    };
  },

  mounted() {
    this.getData();
    const userStore = useUserStore();
    this.user = userStore.user;
  },

  beforeRouteLeave(to, from, next) {
    if (this.eventModified) {
      this.dialogVisible = true;
      this.eventModified = false;
      this.toURL = to;
    } else {
      next();
    }
  },

  watch: {
    event: {
      handler(newValue, oldValue) {
        if (this.isLoaded) {
          this.eventModified = true;
        }
      },
      deep: true,
    },
  },

  computed: {
    divider() {
      if (this.event) {
        let divider = this.dividers.find(
          (d) => d.name == this.event.properties.divider
        );
        if (!divider) return;
        return divider.divider.main;
      }
    },
  },

  methods: {
    ...mapActions(useUserStore, ["authenticateUser"]),

    getData() {
      this.$axiosAuth
        .get(`host/event/`)
        .then((res) => {
          this.event = res.data[0];
          this.loading = false;
        })
        .then(() => {
          this.isLoaded = true;
        })
        .catch((err) => {
          this.isLoaded = true;
          this.loading = false;
          ElNotification({
            title: "Error",
            message: "Something went wrong, contact support for help",
            type: "error",
          });
        });
    },

    editEvent() {
      this.loading = true;
      let formData = new FormData();
      let properties = {
        layout: this.event.properties.layout,
        font: this.event.properties.font,
        fontAll: this.event.properties.fontAll,
        clipPath: this.event.properties.clipPath,
        animate: this.event.properties.animate,
        showGiftPrices: this.event.properties.showGiftPrices,
        divider: this.event.properties.divider,
        img_source: this.event.properties.img_source,
        showCustomFields: this.event.properties.showCustomFields,
        show_date: this.event.properties.show_date,
        customFieldsTitle: this.event.properties.customFieldsTitle,
        addImagePadding: this.event.properties.addImagePadding,
        notifications: this.event.properties.notifications,
        img_download_location: this.event.properties.img_download_location,
        closeChromeAlert: this.event.properties.closeChromeAlert,
        closeGiftAlert: this.event.properties.closeGiftAlert,
        extension_installed: this.event.properties.extension_installed,
        colors: {
          accent: this.event.properties.colors.accent,
          background: this.event.properties.colors.background,
          heading: this.event.properties.colors.heading,
          paragraph: this.event.properties.colors.paragraph,
          additionalDetails: this.event.properties.colors.additionalDetails,
        },
      };

      formData.append("name", this.event.name); // TODO: event doesn't have a name at the moment so this is coming up in console as error
      formData.append("name_host1", this.event.name_host1);
      formData.append("name_host2", this.event.name_host2);
      if (this.event.start_datetime && !this.event.has_been_live) {
        formData.append("start_datetime", this.event.start_datetime);
      }
      if (!this.event.has_been_edited) {
        formData.append("has_been_edited", true);
      }
      formData.append("description", this.event.description);
      formData.append("hashtag", this.event.hashtag);
      formData.append("location", this.event.location);
      formData.append("img_url", this.event.img_url);
      formData.append("img_url_stock", this.event.img_url_stock);
      formData.append("properties", JSON.stringify(properties));

      if (this.event.img && this.event.imageUploaded) {
        formData.append("img", this.event.img);
      }

      formData.append(
        "custom_user_fields",
        JSON.stringify(this.event.custom_user_fields)
      );

      this.$axiosAuth
        .patch(`host/event/${this.event.event_id}/`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          this.persistPinia();
          this.loading = false;
          ElNotification({
            title: "Success",
            message: "Event updated",
            type: "success",
          });
          this.eventModified = false;
          if (this.dialogVisible) {
            this.dialogVisible = false;
          }
          if (this.toURL) {
            setTimeout(() => {
              this.$router.push({ name: this.toURL.name });
            }, 2000);
          }
        })
        .catch((err) => {
          this.loading = false;
          ElNotification({
            title: "Error",
            message: "Something went wrong, contact support for help",
            type: "error",
          });
          if (this.dialogVisible) {
            this.dialogVisible = false;
          }
          if (this.toURL) {
            this.$router.push({ name: this.toURL.name });
          }
        });
    },

    persistPinia() {
      let user = {};
      user.email = this.user.email;
      user.pk = this.user.pk;
      user.username = this.user.username;
      user.email_verified = this.user.email_verified;

      //then the  data from the event creation
      user.start_datetime = this.event.start_datetime;
      user.host_name1 = this.event.name_host1;
      user.host_name2 = this.event.name_host2;

      // now add to Pinia
      this.authenticateUser(true, user);
    },

    navigate() {
      this.$router.push({ name: this.toURL.name });
    },
  },
};
</script>

<style scoped>
.hero {
  mask: v-bind(divider);
  clip-path: v-bind(divider);
}
</style>
