<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-background="#ffffff"
  ></div>
  <AdminLayout v-if="!loading">
    <div class="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
      <div class="space-y-8">
        <h1 class="text-2xl font-bold leading-7 text-black">Contributions</h1>
        <div class="grid grid-cols-1 gap-3 sm:grid-cols-5">
          <ContributionsCards
            v-if="event && contributions"
            :event="event"
            :contributions="contributions"
            :report="true"
          />
        </div>
        <div></div>
        <div v-if="event && gifts && showGiftTable" class="space-y-4">
          <div class="flex justify-between">
            <h2 class="text-lg font-bold">Contributions by Gift</h2>
            <div class="flex gap-x-1 text-gray-700">
              <Bars4Icon class="hidden h-6 w-6 lg:block" aria-hidden="true" />
              List
              <Toggle :enabled="showGiftTable" v-model="showGiftTable" />
              <GiftIcon class="hidden h-6 w-6 lg:block" aria-hidden="true" />
              By Gift
            </div>
          </div>
          <GiftsTable :event="event" :gifts="gifts" />
        </div>
        <div v-if="!showGiftTable" class="space-y-4">
          <div class="flex justify-between">
            <div class="flex items-center gap-x-2">
              <h2 class="text-lg font-bold">All Contributions</h2>
              <el-button
                v-if="!userMobile"
                @click="exportContributions()"
                type="primary"
                size="small"
                plain
                >Export list</el-button
              >
            </div>
            <div class="flex gap-x-1 text-gray-700">
              <Bars4Icon class="hidden h-6 w-6 lg:block" aria-hidden="true" />
              List
              <Toggle :enabled="showGiftTable" v-model="showGiftTable" />
              <GiftIcon class="hidden h-6 w-6 lg:block" aria-hidden="true" />
              By Gift
            </div>
          </div>
          <ContributionsTable
            v-if="event && contributions"
            :event="event"
            :contributions="contributions"
          />
        </div>
      </div>
    </div>
  </AdminLayout>
</template>

<script>
import AdminLayout from "@/layouts/Admin.vue";
import ContributionsCards from "@/components/admin/ContributionsCards.vue";
import ContributionsTable from "../../components/admin/ContributionsTable.vue";
import GiftsTable from "../../components/admin/GiftsTable.vue";
import Toggle from "@/components/admin/Toggle.vue";
import { GiftIcon } from "@heroicons/vue/24/outline";
import { Bars4Icon } from "@heroicons/vue/24/outline";

export default {
  components: {
    AdminLayout,
    ContributionsCards,
    ContributionsTable,
    GiftsTable,
    Toggle,
    GiftIcon,
    Bars4Icon,
  },
  data() {
    return {
      loading: false,
      event: null,
      contributions: null,
      gifts: null,
      showGiftTable: null,
    };
  },
  mounted() {
    this.loading = true;
    this.getData();
  },
  watch: {
    showGiftTable() {
      localStorage.setItem("showGiftTable", JSON.stringify(this.showGiftTable));
    },
  },
  methods: {
    getData() {
      this.showGiftTable = localStorage.getItem("showGiftTable")
        ? JSON.parse(localStorage.getItem("showGiftTable"))
        : false;
      Promise.all([
        this.$axiosAuth.get(`host/event/`),
        this.$axiosAuth.get(`host/contribution/`),
        this.$axiosAuth.get(`host/gift/`),
      ])
        .then((res) => {
          this.event = res[0].data[0];
          this.contributions = res[1].data;
          this.gifts = res[2].data;
        })
        .then((res) => {
          this.loading = false;
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: "Something went wrong, contact support for help",
            type: "error",
          });
          this.loading = false;
        });
    },

    exportContributions() {
      this.$axiosAuth
        .get(`/host/download-contributions/`, { responseType: "blob" })
        .then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", "contributions.csv"); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch((err) => {
          console.log(err);
          ElNotification({
            title: "Error",
            message: "Something went wrong, contact support for help",
            type: "error",
          });
        });
    },
  },
};
</script>
